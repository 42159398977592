._5VoKX {
  display: none;
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 600px;
  background: #fff;
  color: #000;
  /* padding: 12px; */
}

.ZRl7B {
  display: block;
  padding-top: 65%;
  background-image: url(/assets/src/components/SalePopIn/benlux-saint-valentin-2025.jpg);
  background-size: cover;
}

._5VoKX.visible {
  display: block;
}

/* .modal > a {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  /* color: #f2a6b1 !important; */
  /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
} */

/* .modal > a:hover {
  color: #000 !important;
} */

._3sgzn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

._3sgzn:before,
._3sgzn:after {
  position: absolute;
  left: 14px;
  top: 5px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #000;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}

._3sgzn:before {
  transform: rotate(45deg);
}
._3sgzn:after {
  transform: rotate(-45deg);
}

._3sgzn:hover::before,
._3sgzn:hover::after {
  opacity: 1;
}

._1ff87 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  display: none;
  opacity: 0.5;
  background-color: #000;
}

._1ff87.visible {
  display: block;
}
